import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';

import Permission from '../pages/Permission';
import User from '../pages/User';

import { Unit } from '../pages/Unit';
import { Quiz } from '../pages/Quiz';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect path="/" to="/signin" exact />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />

      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/permissions" component={Permission} isPrivate />
      <Route path="/users" component={User} isPrivate />

      <Route path="/units" component={Unit} isPrivate />

      <Route path="/quiz" component={Quiz} isPrivate />

      {/* <Route path="/profile" component={Profile} isPrivate /> */}
    </Switch>
  );
};

export default Routes;
