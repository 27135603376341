import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { GoNote } from 'react-icons/go';
import { MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams } from 'shared/interfaces';

import { useToast } from 'contexts';

import * as C from 'components';
import { typeQuestion } from './options';

import { createQuiz, getQuiz, updateQuiz } from '../api';

import * as S from './styles';

import { IQuiz, IQuizForm, IQuestions2 } from '../interfaces';

const schema = Yup.object().shape({
  name: Yup.string().required('Descrição é obrigatória'),
  quiz_questions: Yup.array()
    .of(
      Yup.object()
        .shape({
          question: Yup.string().required('Pergunta obrigatória'),
          type_question: Yup.object()
            .shape({
              label: Yup.string().required('Tipo pergunta é obrigatória'),
              value: Yup.string().required('Tipo pergunta é obrigatória'),
            })
            .typeError('Tipo pergunta é obrigatória')
            .required('Tipo pergunta é obrigatória'),
        })
        .required('Pergunta obrigatória')
        .typeError('Pergunta obrigatória'),
    )
    .required('Itens obrigatório'),
});

export const QuizForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      quiz_questions: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'quiz_questions',
  });

  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState<IQuiz>();

  const quizQuestions: IQuestions2[] = watch('quiz_questions');
  const typeQuestionAtual = watch('type_question_atual');
  const questionAtual = watch('question_atual');

  useEffect(() => {
    if (id) {
      getQuiz(id).then((data) => {
        setQuiz(data);

        reset(data);
      });
    }
  }, [id, reset]);

  useEffect(() => {
    if (fields.length !== quizQuestions.length) {
      // Devido bug na tela de update ao deletar ultimo item
      setValue('quiz_questions', fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, setValue]);

  const onSubmit = useCallback(
    async (data: IQuizForm) => {
      if (data.quiz_questions.length === 0) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ao menos uma pergunta é obrigatória',
        });

        return;
      }

      try {
        setLoading(true);

        if (id) {
          const response = await updateQuiz(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/quiz');
          }
        } else {
          await createQuiz(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/quiz');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  const handleAdd = useCallback(() => {
    if (questionAtual && typeQuestionAtual) {
      append({
        question: questionAtual,
        type_question: typeQuestionAtual,
      });

      setValue('question_atual', '');
    } else {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Tipo e Pergunta obrigatória',
      });
    }
  }, [addToast, append, questionAtual, setValue, typeQuestionAtual]);

  const handleRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/quiz">
              <MdKeyboardBackspace size={24} color="#fff" />
              Questionários
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id ? `Editando questionário: ${quiz?.name}` : 'Novo Questionário'
            }
            icon={GoNote}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormHeaderContainer>
            <C.FormGroup>
              <C.Label>Descrição</C.Label>
              <C.Input
                type="text"
                {...register('name')}
                errors={errors.name}
                name="name"
                placeholder="Descrição"
              />
            </C.FormGroup>
          </S.FormHeaderContainer>

          <S.Table>
            <thead>
              <tr>
                <th className="text-left" style={{ width: '20%' }}>
                  Tipo
                </th>
                <th className="text-left" style={{ width: '70%' }}>
                  Pergunta
                </th>
                <th className="text-center" style={{ width: '10%' }}>
                  Ação
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="20%">
                  <C.ReactSelect
                    {...register('type_question_atual')}
                    name="type_question_atual"
                    control={control}
                    errors={errors.type_question_atual}
                    options={typeQuestion}
                    defaultValue="1"
                  />
                </td>
                <td width="70%">
                  <C.Input
                    type="text"
                    {...register('question_atual')}
                    errors={errors.question_atual}
                    name="question_atual"
                    placeholder="Informe a pergunta"
                  />
                </td>
                <td className="text-center" width="10%">
                  <S.IconAdd
                    title="Adicionar"
                    size={24}
                    color="#707070"
                    onClick={() => handleAdd()}
                  />
                </td>
              </tr>
            </tbody>
          </S.Table>

          <S.FormHeader>
            <div>Total ({quizQuestions.length})</div>
          </S.FormHeader>

          <S.Table>
            <tbody>
              {fields.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td width="20%" className="text-center">
                      <C.ReactSelectAsync
                        {...register(`quiz_questions[${index}].type_question`)}
                        control={control}
                        errors={
                          errors.quiz_questions &&
                          errors.quiz_questions[index]?.type_question
                        }
                        defaultValue={
                          quizQuestions[index]?.type_question || '1'
                        }
                        isDisabled
                      />
                    </td>

                    <td width="70%" className="text-left">
                      <C.Input
                        {...register(`quiz_questions[${index}].question`)}
                        errors={
                          errors.quiz_questions &&
                          errors.quiz_questions[index]?.question
                        }
                        name={`quiz_questions[${index}].question`}
                        defaultValue={quizQuestions[index]?.question}
                        readOnly
                      />
                    </td>

                    <td>
                      <S.IconDelete
                        title="Remover"
                        size={24}
                        color="#707070"
                        onClick={() => handleRemove(index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </S.Table>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
