import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { IUnit, IUnitForm } from './interfaces';

const createFormData = (unit: IUnitForm) => {
  const formData = new FormData();

  if (unit.image_upload?.length) {
    formData.append('image', unit.image_upload[0]);
  }

  formData.append('data', JSON.stringify(unit));

  return formData;
};

const getUnit = async (id: string): Promise<IUnit> => {
  const response = await api.get<IUnit>(`/units/${id}`);

  return response.data;
};

const getUnits = async (params: IParams): Promise<IPaginate<IUnit>> => {
  const response = await api.get<IPaginate<IUnit>>('/units', {
    params,
  });

  return response.data;
};

const createUnit = async (unit: IUnitForm): Promise<void> => {
  const data = createFormData(unit);

  await api.post('/units', data);
};

const updateUnit = async (id: string, unit: IUnitForm): Promise<IUnit> => {
  const data = createFormData(unit);

  const response = await api.put<IUnit>(`/units/${id}`, data);

  return response.data;
};

const deleteUnit = async (id: string): Promise<void> => {
  await api.delete(`/units/${id}`);
};

export { getUnit, getUnits, createUnit, updateUnit, deleteUnit };
