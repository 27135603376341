import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import { typeQuestion } from './Form/options';

import {
  IQuiz,
  IQuizForm,
  IQuizAnswersScoreResponse,
  IQuizAnswersTextResponse,
  IQuizAnswersScoreParams,
} from './interfaces';

const createQuizData = (quiz: IQuizForm) => {
  const questions = quiz.quiz_questions.map((item) => {
    return {
      ...item,
      type_question: item.type_question.value,
    };
  });

  const data = {
    name: quiz.name,
    quiz_questions: questions,
  };

  return data;
};

const getQuiz = async (id: string): Promise<IQuiz> => {
  const { data } = await api.get<IQuiz>(`/quiz/${id}`);

  const questions = data.quiz_questions.map((q) => {
    const options = typeQuestion.filter((o) => o.value === q.type_question);

    return {
      ...q,
      type_question: {
        value: String(q.type_question) || '1',
        label: String(options[0].label) || 'Opções',
      },
    };
  });

  return { ...data, quiz_questions: questions };
};

const getQuizs = async (params: IParams): Promise<IPaginate<IQuiz>> => {
  const response = await api.get<IPaginate<IQuiz>>('/quiz', {
    params,
  });

  return response.data;
};

const createQuiz = async (venda: IQuizForm): Promise<void> => {
  const data = createQuizData(venda);

  await api.post('/quiz', data);
};

const updateQuiz = async (id: string, venda: IQuizForm): Promise<IQuiz> => {
  const data = createQuizData(venda);

  const response = await api.put<IQuiz>(`/quiz/${id}`, data);

  return response.data;
};

const deleteQuiz = async (id: string): Promise<void> => {
  await api.delete(`/quiz/${id}`);
};

const updateStatus = async (id: string): Promise<IQuiz> => {
  const response = await api.get(`/quiz/update/status/${id}`);

  return response.data;
};

const reportQuizAnswersScore = async (
  data: IQuizAnswersScoreParams,
): Promise<IQuizAnswersScoreResponse[]> => {
  const response = await api.post(`/quiz/reports/answers-score/`, data);

  return response.data;
};

const reportQuizAnswersText = async (
  data: IQuizAnswersTextResponse,
): Promise<IQuizAnswersTextResponse[]> => {
  const response = await api.post(`/quiz/reports/answers-text/`, data);

  return response.data;
};

export {
  getQuiz,
  getQuizs,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  updateStatus,
  reportQuizAnswersScore,
  reportQuizAnswersText,
};
