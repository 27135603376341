import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { QuizList } from './List';
import { QuizForm } from './Form';
import { AnswersReport } from './AnswersReport';

export const Quiz: React.FC = () => {
  return (
    <Switch>
      <Route path="/quiz" component={QuizList} isPrivate exact />
      <Route path="/quiz/new" component={QuizForm} isPrivate />
      <Route path="/quiz/edit/:id" component={QuizForm} isPrivate />
      <Route
        path="/quiz/answers/report/:id"
        component={AnswersReport}
        isPrivate
      />
    </Switch>
  );
};
