import React, { ReactElement } from 'react';

import { MdDashboard, MdAssignment } from 'react-icons/md';
import { GoSettings } from 'react-icons/go';

import { useSiedeBar } from 'contexts/SideBarProvider';
import { useAuth } from 'contexts/AuthProvider';

import ItemMenu from './ItemMenu';

import { Container, MainMenu } from './styles';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
  perm?: string;
  type: 'access' | 'dashboard';
}

const SideBar: React.FC = () => {
  const { user, access } = useAuth();
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [];

  const userAccessMenu: Menu[] = [];

  if (access.includes('dashboard_list')) {
    userAccessMenu.push({
      name: 'Dashboard',
      route: '#',
      type: 'access',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [
        {
          name: 'Home',
          route: '/dashboard',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
        {
          name: 'Resumo Sessões',
          route: '/dashboard/session/resume',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
      ],
    });
  }

  if (
    access.includes('users_list') ||
    access.includes('permissions_list') ||
    access.includes('units_list') ||
    access.includes('quiz_list')
  ) {
    userAccessMenu.push({
      name: 'Cadastros',
      route: '#',
      type: 'access',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          type: 'access',
          perm: 'permissions_list',
          children: [],
        },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          type: 'access',
          perm: 'users_list',
          children: [],
        },
        {
          name: 'Unidades',
          route: '/units',
          icon: null,
          type: 'access',
          perm: 'units_list',
          children: [],
        },
        {
          name: 'Questionários',
          route: '/quiz',
          icon: null,
          type: 'access',
          perm: 'quiz_list',
          children: [],
        },
      ],
    });
  }

  const adminMenu: Menu[] = [
    {
      name: 'Dashboard',
      route: '#',
      type: 'access',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [
        {
          name: 'Home',
          route: '/dashboard',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
        {
          name: 'Resumo Sessões',
          route: '/dashboard/session/resume',
          icon: null,
          type: 'access',
          perm: 'dashboard_list',
          children: [],
        },
      ],
    },
    {
      name: 'Cadastros',
      route: '#',
      type: 'access',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          type: 'access',
          children: [],
        },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          type: 'access',
          children: [],
        },
      ],
    },
  ];

  if (user.role === 'admin') {
    adminMenu.map((adm) => menus.push(adm));
  }

  userAccessMenu.map((usr) => menus.push(usr));

  return (
    <Container isMenuOpened={isOpened}>
      <MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </MainMenu>
    </Container>
  );
};

export default SideBar;
