import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RiBankFill } from 'react-icons/ri';
import { MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams } from 'shared/interfaces';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { createUnit, getUnit, updateUnit } from '../api';
import { IUnit, IUnitForm } from '../interfaces';

export const UnitForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState<IUnit>();

  const { id } = useParams<IRouterParams>();

  let schema;

  if (id) {
    schema = Yup.object().shape({
      name: Yup.string().required('Descrição obrigatória'),
      login: Yup.string().required('Login obrigatório'),
      image_upload: Yup.mixed(),
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required('Descrição obrigatória'),
      login: Yup.string().required('Login obrigatório'),
      password: Yup.string().required('Senha obrigatória'),
      confirm_password: Yup.string()
        .required('Confirmação de senha obrigatoria')
        .oneOf([Yup.ref('password'), 'Confirmação invalida']),
      image_upload: Yup.mixed(),
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      login: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id) {
      getUnit(id).then((data) => {
        setUnit(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IUnitForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateUnit(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/units');
          }
        } else {
          const newData = data; // copiar variavel evitar erro ESLINT
          delete newData?.confirm_password;

          await createUnit(newData);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/units');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset],
  );

  const renderPassword = useCallback(() => {
    return (
      <>
        <C.FormGroup>
          <C.Label>Senha</C.Label>
          <C.Input
            {...register('password')}
            type="password"
            errors={errors.password}
            name="password"
            placeholder="Senha"
            autoComplete="off"
          />
        </C.FormGroup>

        <C.FormGroup>
          <C.Label>Confirmação Senha</C.Label>
          <C.Input
            {...register('confirm_password')}
            type="password"
            errors={errors.confirm_password}
            name="confirm_password"
            placeholder="Confirmação da senha"
            autoComplete="off"
          />
        </C.FormGroup>
      </>
    );
  }, [errors.confirm_password, errors.password, register]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/units">
              <MdKeyboardBackspace size={24} color="#fff" />
              Unidades
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={id ? `Editando: ${unit?.name}` : 'Nova unidade'}
            icon={RiBankFill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.Upload
            {...register('image_upload')}
            imageUrl={unit?.image_url}
            fileSelected={watch('image_upload')}
          />

          <C.FormGroup>
            <C.Label>Descrição</C.Label>
            <C.Input
              type="text"
              {...register('name')}
              errors={errors.name}
              name="name"
              placeholder="Descrição"
            />
          </C.FormGroup>

          {id ? (
            <C.FormGroup>
              <C.Label>Login</C.Label>
              <C.Input
                {...register('login')}
                type="text"
                errors={errors.login}
                name="login"
                placeholder="Login"
                readOnly
              />
            </C.FormGroup>
          ) : (
            <C.FormGroup>
              <C.Label>Login</C.Label>
              <C.Input
                {...register('login')}
                type="text"
                errors={errors.login}
                name="login"
                placeholder="Login"
              />
            </C.FormGroup>
          )}

          {id ? <></> : renderPassword()}

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
