import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UnitList } from './List';
import { UnitForm } from './Form';

export const Unit: React.FC = () => {
  return (
    <Switch>
      <Route path="/units" component={UnitList} isPrivate exact />
      <Route path="/units/new" component={UnitForm} isPrivate />
      <Route path="/units/edit/:id" component={UnitForm} isPrivate />
    </Switch>
  );
};
