import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { useHistory } from 'react-router-dom';
import { GoNote } from 'react-icons/go';
import { MdSearch } from 'react-icons/md';
import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';

import * as C from 'components';

import { getQuizs, deleteQuiz, updateStatus } from '../api';

import * as S from './styles';

import { IQuiz } from '../interfaces';

export const QuizList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [quiz, setQuiz] = useState<IPaginate<IQuiz>>();
  const [quizAtual, setQuizAtual] = useState<IQuiz>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRef = useRef<ModalHandles>(null);
  const [modalType, setModalType] = useState('remove');

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
      };

      getQuizs(params)
        .then((data) => {
          setQuiz(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = useCallback(async () => {
    try {
      await deleteQuiz(quizAtual!.id);

      if (!quiz) return;

      const newList = quiz.data.filter((item) => item.id !== quizAtual?.id);
      setQuiz({ ...quiz, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Registro removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao remover o registro',
      });
    }
  }, [addToast, quizAtual, quiz]);

  const handleStatus = useCallback(() => {
    try {
      updateStatus(quizAtual!.id)
        .then((c) => {
          if (quiz && c) {
            const newQuiz = quiz.data.map((item) => {
              if (item.id === quizAtual?.id) {
                if (item.status === 'I') {
                  return { ...item, status: 'A' };
                }

                return { ...item, status: 'I' };
              }

              return item;
            });

            setQuiz({ ...quiz, data: newQuiz });
          }
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          modalRef.current?.closeModal();
        });
    } catch (error) {
      modalRef.current?.closeModal();
    } finally {
      modalRef.current?.closeModal();
    }
  }, [addToast, quiz, quizAtual]);

  const handleOpenModal = useCallback(
    (quizSelected: IQuiz, type: string): void => {
      setModalType(type);

      setQuizAtual(quizSelected);

      modalRef.current?.openModal();
    },
    [],
  );

  const renderModal = useMemo(() => {
    if (modalType === 'remove') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>O registro será removido completamente!</p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleRemove()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a exclusão
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    if (modalType === 'status') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>
              {quizAtual?.status === 'A'
                ? 'Confirma desativação questionário?'
                : 'Confirma ativação do questionário?'}
            </C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>
              {quizAtual?.status === 'A'
                ? 'O questionário será desabilitado para uso do mobile!'
                : 'O questionário será habilitado para uso do mobile!'}
            </p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleStatus()}
                style={{ marginLeft: '16px' }}
              >
                {quizAtual?.status === 'A'
                  ? ' SIM, confirmo a desativação!'
                  : ' SIM, confirmo a ativação!'}
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    return <></>;
  }, [handleRemove, handleStatus, modalType, quizAtual?.status]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Questionários" icon={GoNote} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({
                      page: 1,
                      per_page: quiz?.per_page,
                    })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <S.HeaderButtonContainer>
            <C.Can checkAccess="quiz_create">
              <C.ButtonLink title="Adicionar Questionario" to="/quiz/new">
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </S.HeaderButtonContainer>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th style={{ width: '40px' }} className="text-center">
                      Status
                    </th>
                    <th className="text-left">Descrição</th>
                    <th style={{ width: '80px' }} className="text-center">
                      Relatório
                    </th>
                    <th style={{ width: '80px' }} className="text-center">
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quiz &&
                    quiz.data.map((item: IQuiz) => (
                      <tr key={item.id}>
                        <td className="text-center">
                          <S.Status
                            size={24}
                            enable={String(item.status === 'A')}
                          />
                        </td>
                        <td className="text-left">{item.name}</td>
                        <td className="text-left">
                          <S.IconReportContainer>
                            <button
                              type="button"
                              onClick={() =>
                                history.push(`/quiz/answers/report/${item.id}`)
                              }
                            >
                              <S.IconReport
                                title="Relatorio"
                                size={24}
                                color="#707070"
                              />
                            </button>
                          </S.IconReportContainer>
                        </td>

                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkAccess="quiz_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/quiz/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkAccess="quiz_update">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'status')}
                              >
                                <S.IconEnable
                                  title="Modificar Status"
                                  size={24}
                                  color="#707070"
                                />
                                Status
                              </button>
                            </C.Can>

                            <C.Can checkAccess="quiz_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'remove')}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {quiz && (
            <C.Pagination
              page={quiz?.page}
              total={quiz?.total}
              per_page={quiz?.per_page}
              last_page={quiz?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      {renderModal}
    </S.Container>
  );
};
