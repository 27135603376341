import React, { createContext, useCallback, useState, useContext } from 'react';
import { isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { singIn, changeAdminUser, returnAdminUser } from 'services/api/SignIn';
import api from 'services/api';
import { IAuthContextData, IAuthState, IUser, IUsersOnline } from './types';

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@Quiz:token');
    const tokenAdmin = localStorage.getItem('@Quiz:tokenAdmin');
    const tokenExpires = localStorage.getItem('@Quiz:tokenExpires');
    const user = localStorage.getItem('@Quiz:user');
    const access = localStorage.getItem('@Quiz:access');

    if (!tokenExpires) {
      // Para evitar erros caso não tenha tokenExpires
      localStorage.removeItem('@Quiz:token');
      localStorage.removeItem('@Quiz:tokenAdmin');
      localStorage.removeItem('@Quiz:tokenExpires');
      localStorage.removeItem('@Quiz:user');
      localStorage.removeItem('@Quiz:access');

      return {} as IAuthState;
    }

    if (
      tokenExpires &&
      isBefore(
        zonedTimeToUtc(tokenExpires, 'America/Sao_Paulo'),
        zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
      )
    ) {
      localStorage.removeItem('@Quiz:token');
      localStorage.removeItem('@Quiz:tokenAdmin');
      localStorage.removeItem('@Quiz:tokenExpires');
      localStorage.removeItem('@Quiz:user');
      localStorage.removeItem('@Quiz:access');

      return {} as IAuthState;
    }

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        tokenAdmin: tokenAdmin || '',
        user: JSON.parse(user),
        access: access ? JSON.parse(access) : [],
      };
    }

    return {} as IAuthState;
  });

  const [usersOnline, setUsersOnline] = useState<IUsersOnline[]>([
    {},
  ] as IUsersOnline[]);

  const updateUsersOnline = useCallback((users: IUsersOnline[]) => {
    if (users.length > 0) {
      setUsersOnline(users);
    }
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await singIn({ email, password });

    const { token, user, expires_in, access } = response;

    localStorage.setItem('@Quiz:token', token);
    localStorage.setItem('@Quiz:tokenAdmin', '');
    localStorage.setItem('@Quiz:tokenExpires', expires_in);
    localStorage.setItem('@Quiz:user', JSON.stringify(user));

    localStorage.setItem('@Quiz:access', JSON.stringify(access));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, tokenAdmin: '', user, access });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Quiz:token');
    localStorage.removeItem('@Quiz:tokenAdmin');
    localStorage.removeItem('@Quiz:tokenExpires');
    localStorage.removeItem('@Quiz:user');
    localStorage.removeItem('@Quiz:permissions');
    localStorage.removeItem('@Quiz:access');

    setData({} as IAuthState);
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      setData({
        ...data,
        token: data.token,
        user,
      });
      localStorage.setItem('@Quiz:user', JSON.stringify(user));
    },
    [setData, data],
  );

  const changeUser = useCallback(async (user_id: string) => {
    const response = await changeAdminUser(user_id);

    if (response) {
      const { token, token_admin, user, expires_in, access } = response;

      localStorage.setItem('@Quiz:token', token);
      localStorage.setItem('@Quiz:tokenAdmin', token_admin);
      localStorage.setItem('@Quiz:tokenExpires', expires_in);
      localStorage.setItem('@Quiz:user', JSON.stringify(user));
      localStorage.setItem('@Quiz:access', JSON.stringify(access));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, tokenAdmin: token_admin, user, access });
    }
  }, []);

  const returnUser = useCallback(async (tokenAdmin: string) => {
    const response = await returnAdminUser(tokenAdmin);

    if (response) {
      const { token, user, expires_in, access } = response;

      localStorage.setItem('@Quiz:token', token);
      localStorage.setItem('@Quiz:tokenAdmin', '');
      localStorage.setItem('@Quiz:tokenExpires', expires_in);
      localStorage.setItem('@Quiz:user', JSON.stringify(user));

      localStorage.setItem('@Quiz:access', JSON.stringify(access));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, tokenAdmin: '', user, access });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        tokenAdmin: data.tokenAdmin,
        usersOnline,
        access: data.access,
        signIn,
        signOut,
        updateUser,
        updateUsersOnline,
        changeUser,
        returnUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
