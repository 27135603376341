import React, { useState, useEffect, useCallback } from 'react';

import { useForm, FieldValues } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import { MdSearch, MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams } from 'shared/interfaces';
import colors from 'styles/colors';
import { useToast } from 'contexts';

import * as C from 'components';

import { reportQuizAnswersScore, reportQuizAnswersText, getQuiz } from '../api';

import * as S from './styles';

import {
  IQuizAnswersScoreResponse,
  IQuizAnswersTextResponse,
  IQuizAnswersScoreParams,
} from '../interfaces';

interface IChartPieProps {
  name: string;
  value: number;
  key: string;
  percent?: number;
  color?: string;
}

export const AnswersReport: React.FC = () => {
  const currentDate = new Date();

  const start_date = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );

  const end_date = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );

  const { id } = useParams<IRouterParams>();

  const { addToast } = useToast();

  const [answersScore, setAnswersScore] = useState<IQuizAnswersScoreResponse[]>(
    [] as IQuizAnswersScoreResponse[],
  );
  const [answersText, setAnswersText] = useState<IQuizAnswersTextResponse[]>(
    [] as IQuizAnswersTextResponse[],
  );

  const [quiz, setQuiz] = useState('');
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<IQuizAnswersScoreParams>({
    quiz_id: id || '',
    start_date,
    end_date,
  });

  useEffect(() => {
    if (id) {
      const response = getQuiz(id);

      response.then((res) => setQuiz(res.name));
    }
  }, [id]);

  const handleSearch = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const newData = await reportQuizAnswersScore(data);
        const newTextData = await reportQuizAnswersText(data);

        setAnswersScore(newData);
        setAnswersText(newTextData);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    handleSearch(filters);
  }, [filters, handleSearch]);

  const onSubmit = useCallback(
    async (data) => {
      setFilters({ ...filters, ...data });
    },

    [filters],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      start_date: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ),
      end_date: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ),
    },
  });

  const renderCharts = useCallback(
    (item: IQuizAnswersScoreResponse, idx: number) => {
      const data: IChartPieProps[] = [
        {
          name: 'Péssimo',
          value: Number(item.pessimo),
          key: '0',
          color: colors.answer1,
        },
        {
          name: 'Ruim',
          value: Number(item.ruim),
          key: '1',
          color: colors.answer2,
        },
        {
          name: 'Neutro',
          value: Number(item.neutro),
          key: '2',
          color: colors.answer3,
        },
        {
          name: 'Bom',
          value: Number(item.bom),
          key: '3',
          color: colors.answer4,
        },
        {
          name: 'Excelente',
          value: Number(item.excelente),
          key: '4',
          color: colors.answer5,
        },
      ];

      return (
        <S.CardChart key={String(idx)}>
          <C.ChartPie title={item.question} data={data} size={300} />;
        </S.CardChart>
      );
    },
    [],
  );

  return (
    <S.Container>
      <>
        <S.HeaderBackground>
          <S.FilterContainer>
            <S.BackLink>
              <Link to="/quiz">
                <MdKeyboardBackspace size={24} color="#fff" />
                Questionários
              </Link>
            </S.BackLink>

            <form onSubmit={handleSubmit(onSubmit)}>
              <S.FormGroup>
                <C.Label>Data Inicial</C.Label>
                <C.InputDatePicker
                  {...register('start_date')}
                  control={control}
                  errors={errors.start_date}
                  dateFormat="dd/MM/yyyy"
                  selected={watch('start_date')}
                  name="start_date"
                  placeholder="Data Inicial"
                />
              </S.FormGroup>

              <S.FormGroup>
                <C.Label>Data Final</C.Label>
                <C.InputDatePicker
                  {...register('end_date')}
                  control={control}
                  errors={errors.end_date}
                  dateFormat="dd/MM/yyyy"
                  selected={watch('end_date')}
                  name="end_date"
                  placeholder="Data Final"
                />
              </S.FormGroup>

              <S.ButtonContainer>
                <C.Button
                  variant="primary"
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  title="Gerar relatorio"
                >
                  <MdSearch size={24} />
                </C.Button>
              </S.ButtonContainer>
            </form>
          </S.FilterContainer>
        </S.HeaderBackground>

        <S.HeaderTitle>{quiz}</S.HeaderTitle>

        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <S.ChartsContent>
            {answersScore?.map((item, idx) => {
              return renderCharts(item, idx);
            })}
          </S.ChartsContent>
        )}

        {answersText && answersText[0] && (
          <S.CardTable>
            <S.Panel>
              <div>
                <C.TableResponsive>
                  <C.Table>
                    <thead>
                      <tr>
                        <th className="text-left">Pergunta</th>
                        <th className="text-left">Resposta</th>
                      </tr>
                    </thead>
                    <tbody>
                      {answersText &&
                        answersText.map((item, idx: number) => (
                          <tr key={String(idx)}>
                            <td>{item.question}</td>
                            <td>{item.answer}</td>
                          </tr>
                        ))}
                    </tbody>
                  </C.Table>
                </C.TableResponsive>
              </div>
            </S.Panel>
          </S.CardTable>
        )}
      </>
    </S.Container>
  );
};
