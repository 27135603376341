import React, { createContext, useCallback, useState, useContext } from 'react';
import { IThemeContextData, IThemeState } from './types';

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

const ThemeProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IThemeState>(() => {
    const theme = localStorage.getItem('@Quiz:theme');

    if (theme === 'dark') {
      document.body.classList.remove('ligth-theme');
      document.body.classList.add('dark-theme');
      return { theme } as IThemeState;
    }
    document.body.classList.remove('dark-theme');
    document.body.classList.add('light-theme');

    return { theme: 'light' } as IThemeState;
  });

  const onChangeTheme = useCallback((themeSelected: string) => {
    localStorage.setItem('@Quiz:theme', themeSelected);
    setData({ theme: themeSelected });

    if (themeSelected === 'dark') {
      document.body.classList.remove('ligth-theme');
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme: data.theme, onChangeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme(): IThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within anThemeProvider');
  }

  return context;
}

export { ThemeProvider, useTheme };
