export default {
  // palleta de cores:
  // #1a5ea3
  // #2687E9
  // #519fed

  // #ab003c
  // #f50057
  // #f73378

  primary: '#2687E9',
  second: '#6c757d',
  success: '#28a745',
  error: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',

  textHeader: '#fff',
  textButton: '#fff',

  linkActive: '#191920',
  disabled: '#dddddd',

  focus: '#edff00',
  bg: '#F1F4F9',
  border: '#dddddd',
  placeholder: '#999',
  text: '#444',
  textTable: '#666',
  tableTd: '#666',
  tableBorder: '#EEEEEE',

  answer1: '#EB5341',
  answer2: '#F4BA74',
  answer3: '#FCEB8D',
  answer4: '#9CEB94',
  answer5: '#6DAC65',

  status: {
    PENDENTE: {
      color: '#C1BC35',
      background: '#F0F0DF',
    },
    RETIRADA: {
      color: '#4D85EE',
      background: '#BAD2FF',
    },
    ACTIVE: {
      color: '#2CA42B',
      background: '#DFF0DF',
    },
    DISABLE: {
      color: '#DE3B3B',
      background: '#FAB0B0',
    },
  },
};

export const colorCharts = [
  '#16a085',
  '#2980b9',
  '#f1c40f',
  '#e67e22',
  '#c0392b',
  '#8e44ad',
  '#7f8c8d',
  '#f39c12',
  '#1abc9c',
  '#d35400',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#95a5a6',
  '#27ae60',
  '#e74c3c',
  '#2c3e50',
  '#bdc3c7',
  '#2ecc71',
  '#ecf0f1',
];
