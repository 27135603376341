import styled, { css } from 'styled-components';
import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  tablet: '1450px',
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderBackground = styled.div`
  padding: 10px;

  width: 100%;
  height: 100px;

  background: linear-gradient(
    to left,
    var(--background-header-page-2) 0%,
    var(--background-header-page-1) 100%
  );
`;

export const ChartsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 10px;
  margin: 10px;

  ${customMedia.lessThan('tablet')`
    grid-template-columns: 1fr;
  `}
`;

export const CardTable = styled.div`
  margin: 10px;

  background-color: var(--background-sidebar);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const CardChart = styled.div`
  background-color: var(--background-sidebar);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const FormGroup = styled.div`
  > label {
    margin-bottom: 4px !important;
  }

  > div {
    margin: 8px 8px 8px 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  form {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 80%;
    padding: 0 16px;

    ${customMedia.lessThan('tablet')`
      margin-top: 24px;
    `}
  }
`;

export const ButtonContainer = styled.div`
  margin: 8px 0 0 0;
`;

export const BackLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 32px 0px 4px;

  svg {
    margin-right: 8px;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--background-header-text);
  }
`;

export const HeaderTitle = styled.div`
  padding: 20px;
  color: var(--text);
  font-size: 2rem;
  font-weight: 500;
  line-height: 2rem;
`;

export const Panel = styled.div`
  background: var(--background-panel);
  border-radius: 8px;
  /* position: relative; */
  padding: 10px;
  height: 100%;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const FooterContainer = styled.div`
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--background);
  padding: 0px 16px;
`;
